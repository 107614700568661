import React, { useState } from 'react';
import "./careersGetTouch.css";

export default function CareersGetTouch() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    coverLetter: '',
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="careersG-contact-11">
      <div className="careersG-section-title3">
        <div className="careersG-content24">
          <div className="careersG-open-positions1">Get in Touch</div>
          <div className="careersG-lorem-ipsum-dolor1">
            Fill out the form below to apply for a job
          </div>
        </div>
      </div>
      <form className="careersG-form2" onSubmit={handleSubmit}>
        <div className="careersG-input">
          <label className="careersG-name" htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            className="careersG-typedefault3"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="careersG-input">
          <label className="careersG-name" htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            className="careersG-typedefault3"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="careersG-input">
          <label className="careersG-name" htmlFor="coverLetter">Cover Letter</label>
          <textarea
            id="coverLetter"
            name="coverLetter"
            className="careersG-text-area1"
            value={formData.coverLetter}
            onChange={handleChange}
            required
          />
        </div>
        <div className="careersG-selectedfalse1">
          <input
            type="checkbox"
            id="termsAccepted"
            name="termsAccepted"
            className="careersG-checkbox1"
            checked={formData.termsAccepted}
            onChange={handleChange}
            required
          />
          <label htmlFor="termsAccepted" className="careersG-button1">I agree to the Terms</label>
        </div>
        <button type="submit" className="careersG-styleprimary-smallfalse-da4">
          Submit
        </button>
      </form>
    </div>
  );
}
