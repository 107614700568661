import React from "react"
import "./careersMainBanner.css"
// import CareerImage from "../../images/careersBannerImage.png"
import CareerVideo from "../../images/careersBannerImage.mp4"

export default function CareerMainBanner() {
  
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          {/* <img className="career-home-image" alt="" src={CareerImage} /> */}
          <video 
            className="career-home-image"
            src={CareerVideo}
            loop
            autoPlay
            muted
          />
          <div className="career-image-text-box">
            <div className="career-image-background-text">
              {/* <h1 className="career-image-topic">
                Career
              </h1> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}