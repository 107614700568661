import React, { useState } from 'react';
import "./careersLatestVacancy.css";
import { navigate } from 'gatsby';
import Vector6 from "../../images/vector-6.svg";
import IconMap from "../../images/icon--map.svg";
import TimeFive from "../../images/icon--timefive.svg";
import Vector61 from "../../images/vector-61.svg";

export default function CareersLatestVacancy() {
  const [selectedCategory, setSelectedCategory] = useState("Finance");

  const vacancies = {
    Finance: [
      { title: "General Manager", department: "Finance", location: "Location", contractType: "Full Time" },
      { title: "Accountant", department: "Finance", location: "Location", contractType: "Full Time" }
    ],
    Marketing: [
      { title: "Marketing Manager", department: "Marketing", location: "Location", contractType: "Full Time" },
      { title: "Media Manager", department: "Marketing", location: "Location", contractType: "Full Time" }
    ],
    Production: [
      { title: "Technician", department: "Production", location: "Location", contractType: "Contract" },
      { title: "Production Manager", department: "Production", location: "Location", contractType: "Full Time" }
    ]
  };

  const categories = ["Finance", "Marketing", "Production"];

  const handleViewAll = () => {
    navigate('/all-vacancies', {
      state: { vacancies: vacancies }
    });
  };

  return (
    <div className="career-career-21">
      <div className="career-container3">
        <div className="career-section-title">
          <div className="career-subheading">Tagline</div>
          <div className="career-content4">
            <div className="career-open-positions">Latest Vacancies</div>
            <div className="career-text1">
              Explore our latest job openings and join our team of professionals.
            </div>
          </div>
        </div>
        <div className="career-content5">
          <div className="career-categories">
            {categories.map((category, index) => (
              <div
                key={index}
                className="career-button7"
                onClick={() => setSelectedCategory(category)}
                onKeyDown={(e) => e.key === 'Enter' && setSelectedCategory(category)}
                role="button"
                tabIndex="0"
              >
                <div className={`career-department-one ${selectedCategory === category ? 'active' : ''}`}>{category}</div>
              </div>
            ))}
            <div
              className="career-button6"
              onClick={handleViewAll}
              onKeyDown={(e) => e.key === 'Enter' && handleViewAll()}
              role="button"
              tabIndex="0"
            >
              <div className="career-button-child">
                <div className="career-view-all-parent">
                  <div className="career-view-all1">View all</div>
                  <img className="career-group-child" alt="View all" src={Vector6} />
                </div>
              </div>
            </div>
          </div>
          <div className="career-content6">
            {vacancies[selectedCategory].map((vacancy, index) => (
              <div key={index} className="career-card">
                <div className="career-job">
                  <div className="career-content2">
                    <div className="career-job-title">
                      <div className="career-heading">{vacancy.title}</div>
                      <div className="career-tag">
                        <div className="career-link-one">{vacancy.department}</div>
                      </div>
                    </div>
                    <div className="career-button11">
                      <div className="career-button-child1">
                        <div className="career-apply-now-parent">
                          <div className="career-apply-now">Apply Now</div>
                          <img className="career-group-item" alt="" src={Vector61} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="career-text3">Learn More</div>
                </div>
                <div className="career-info">
                  <div className="career-content8">
                    <img className="career-icon-map" alt="Location" src={IconMap} />
                    <div className="career-text4">{vacancy.location}</div>
                  </div>
                  <div className="career-content8">
                    <img className="career-icon-map" alt="Contract Type" src={TimeFive} />
                    <div className="career-text4">{vacancy.contractType}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
