import React from "react"
import CareerMainBanner from "../templates/Careers/careersMainBanner"
import CareersSuccessStory from "../templates/Careers/careersSuccessStory"
import CareersLatestVacancy from "../templates/Careers/careersLatestVacancy"
import Header from "../components/header"
import Footer from "../components/footer"
import CareersGetTouch from "../templates/Careers/careersGetTouch"

export default function CareersPage() {
  return (
    <div>
      <Header />
      <CareerMainBanner />
      <CareersSuccessStory />
      <CareersLatestVacancy />
      <CareersGetTouch />
      <Footer />
    </div>
  )
}
