import React, { useState } from 'react';
import './careersSuccessStory.css';
import AvatarImage from '../../images/avatar-image@2x.png';
import ButtonLeft from '../../images/icon.svg';
import ButtonRight from '../../images/icon1.svg';

const successStories = [
  {
    quote: "Working at CIC Holdings has been an incredible journey. The company's commitment to innovation and growth has allowed me to thrive and contribute to its success.",
    name: "Viraj Manatunga",
    position: "General Manager",
    avatar: AvatarImage,
  },
  {
    quote: "Working at CIC Holdings has been an incredible journey.",
    name: "Kasun Manatunga",
    position: "Manager",
    avatar: AvatarImage,
  },
];

export default function CareersSuccessStory() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? successStories.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === successStories.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="SuccessStory-testimonial-7">
      <div className="SuccessStory-quote">Success Stories</div>
      <div className="SuccessStory-container2">
        <div className="SuccessStory-content2">
          <div
            className="SuccessStory-button3"
            onClick={handlePrevClick}
            onKeyDown={(e) => e.key === 'Enter' && handlePrevClick()}
            role="button"
            tabIndex="0"
          >
            <img
              className="SuccessStory-chevron-down-icon"
              alt="Previous"
              src={ButtonLeft}
            />
          </div>
          <div className="SuccessStory-content3">
            <div className="SuccessStory-quote1">
              {successStories[currentIndex].quote}
            </div>
            <div className="SuccessStory-avatar">
              <img
                className="SuccessStory-avatar-image-icon"
                alt={successStories[currentIndex].name}
                src={successStories[currentIndex].avatar}
              />
              <div className="SuccessStory-avatar-content">
                <div className="SuccessStory-text">
                  {successStories[currentIndex].name}
                </div>
                <div className="SuccessStory-text1">
                  {successStories[currentIndex].position}
                </div>
              </div>
            </div>
          </div>
          <div
            className="SuccessStory-button3"
            onClick={handleNextClick}
            onKeyDown={(e) => e.key === 'Enter' && handleNextClick()}
            role="button"
            tabIndex="0"
          >
            <img
              className="SuccessStory-chevron-down-icon"
              alt="Next"
              src={ButtonRight}
            />
          </div>
        </div>
        <div className="SuccessStory-slider-dots">
          {successStories.map((_, index) => (
            <div
              key={index}
              className={`SuccessStory-dot ${
                index === currentIndex ? 'active' : ''
              }`}
              onClick={() => setCurrentIndex(index)}
              onKeyDown={(e) => e.key === 'Enter' && setCurrentIndex(index)}
              role="button"
              tabIndex="0"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
